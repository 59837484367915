import React from 'react';
import { Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import styles from './UpcomingWeeklyGospel.module.css';

const UpcomingWeeklyGospel = ({
  upcomingSundayLesson,
  dateString,
  colMd,
  colSm,
  colLg,
}) => {
  const {
    liturgy_week,
    lesson_title,
    bible_source,
    virtue,
    an_file,
    tn_file,
    ns_file,
    hs_file,
  } = upcomingSundayLesson || {};
  return (
    <div className={styles.upcomingGospel}>
      <Row>
        <Col sm={colSm || 12} md={colMd || 6} lg={colLg || 6}>
          <ListGroup>
            <ListGroupItem>
              <b>Date:</b> {dateString}
            </ListGroupItem>
            <ListGroupItem>
              <b>Liturgical Week:</b> {liturgy_week}
            </ListGroupItem>
            <ListGroupItem className={styles.lessonTitle}>
              <b>Lesson:</b> {lesson_title && lesson_title.replace(/\?/g, `'`)}
            </ListGroupItem>
            <ListGroupItem>
              <b>Passage:</b> {bible_source}
            </ListGroupItem>
            {/* <ListGroupItem>
              <b>Key Verse:</b> {KeyVerse}
            </ListGroupItem> */}
            <ListGroupItem>
              <b>Virtue:</b> {virtue}
            </ListGroupItem>
          </ListGroup>
          <br />
        </Col>
        <Col sm={colSm || 12} md={colMd || 6} lg={colLg || 6}>
          <ListGroup>
            <ListGroupItem className={styles.lessonLinks}>
              <b>Lesson Links</b>
            </ListGroupItem>
            <ListGroupItem
              tag="button"
              className={styles.anButton}
              action
              onClick={() =>
                window.open(`https://cms.veym.net/assets/${an_file}`, '_blank')
              }
            >
              Ngành Ấu Nhi
            </ListGroupItem>
            <ListGroupItem
              tag="button"
              className={styles.tnButton}
              action
              onClick={() =>
                window.open(`https://cms.veym.net/assets/${tn_file}`, '_blank')
              }
            >
              Ngành Thiếu Nhi
            </ListGroupItem>
            <ListGroupItem
              tag="button"
              className={styles.nsButton}
              action
              onClick={() =>
                window.open(`https://cms.veym.net/assets/${ns_file}`, '_blank')
              }
            >
              Ngành Nghĩa Sĩ
            </ListGroupItem>
            <ListGroupItem
              tag="button"
              className={styles.hsButton}
              action
              onClick={() =>
                window.open(`https://cms.veym.net/assets/${hs_file}`, '_blank')
              }
            >
              Ngành Hiệp Sĩ
            </ListGroupItem>
          </ListGroup>
          <br />
        </Col>
      </Row>
    </div>
  );
};

export default UpcomingWeeklyGospel;
